import '../App.css';
export const Problems=()=>{
    return (
        <div style={{display:'flex',flexGrow:0.1}}>
         <p style={{color:'#212529',fontSize:'12.3px',fontFamily:"Inter"}}>Facing Problems? Contact us  at 
        <span style={{color:'green',textDecoration:'underline'}}>+91 6382273267
        </span>
        </p>
        </div>
    )
}